import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DestinationChoice from "./DestinationChoice";
import Home from "./Home";
import { DestinationContext } from "./DestinationContext";

const mexicoTheme = createTheme({
  palette: {
    primary: {
      main: "#F25C54",
      contrastText: "#fff",
    },
    secondary: {
      main: "#F7B267",
      contrastText: "#000",
    },
  },
});
const euTheme = createTheme({
  palette: {
    primary: {
      main: "#2b0e0e",
      contrastText: "#fff",
    },
    secondary: {
      main: "#9a568a",
      contrastText: "#000",
    },
    mode: 'dark'
  },
});

export default function App() {
  const [destination, setDestination] = React.useState<
    "eu" | "mx" | undefined
  >();
  const theme = destination === "mx" ? mexicoTheme : euTheme;
  return (
    <ThemeProvider theme={theme}>
      <DestinationContext.Provider value={{ destination, setDestination }}>
        {!destination && <DestinationChoice />}
        {destination && <Home />}
      </DestinationContext.Provider>
    </ThemeProvider>
  );
}
