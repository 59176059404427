import * as React from "react";
import { DestinationContext } from "./DestinationContext";
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

const topLeft = css`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

const flag = css`
  ${topLeft};
  width: 100%;
  height: 100%;
  transition: clip-path 1s, opacity 1s;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  opacity: 0.6;
  &:hover {
    z-index: 1;
    opacity: 1;
  }
`;

const RATIO = 80;

export default function DestinationChoice() {
  const { setDestination } = React.useContext(DestinationContext);
  return (
    <main
      css={css`
        ${topLeft};
        width: 100vw;
        height: 100vh;
        background-color: black;
      `}
    >
      <div
        id="left_flag"
        css={css`
          ${flag};
          clip-path: polygon(0 0, ${RATIO}% 0, ${100 - RATIO}% 100%, 0 100%);
          background-image: url("./img/mexico_flag.jpeg");
          &:hover {
            //  clip-path: polygon(0 0, ${RATIO}% 0, ${RATIO}% 100%, 0 100%);
          }
        `}
        onClick={() => setDestination("mx")}
      />
      <div
        id="right_flag"
        onClick={() => setDestination("eu")}
        css={css`
          ${flag};
          clip-path: polygon(
            ${RATIO}% 0,
            100% 0,
            100% 100%,
            ${100 - RATIO}% 100%
          );
          background-image: url("./img/eu_flag.jpeg");
          &:hover {
            // clip-path: polygon(${100 -
            RATIO}% 0,  100% 0,  100% 100%, ${100 - RATIO}% 100%);
          }
        `}
      />
      <h2
        css={css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
          color: #fff;
          font-family: Roboto, Arial;
          -webkit-text-stroke: 1px black;
          font-size: 8vh;
        `}
      >
        Where do want to go?
      </h2>
    </main>
  );
}
