import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Feature from "./Feature";
import { DestinationContext } from "./DestinationContext";
/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      Fake drone guide {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Home() {
  const { destination } = React.useContext(DestinationContext);
  return (
    <>
      <Box sx={{ bgcolor: "background.paper" }} className="MuiPaper-root">
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: "background.paper",
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              Drone Guide
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="text.secondary"
              paragraph
            >
              The perfect guide for your whole trip.
            </Typography>
          </Container>
        </Box>
        <Container sx={{ py: 8, bgcolor: "background.paper" }} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            <Feature
              title="Headphones"
              image="./img/headphones.jpeg"
              description="You will hear everything even in crowded areas"
            />
            <Feature
              title="Hologram"
              image="./img/hologram.jpeg"
              description="You will have your own personalized hologram"
            />
            <Feature
              title="Guide"
              image="./img/map.jpeg"
              description="You will have the most comprehensive information"
            />
          </Grid>
        </Container>
      </Box>
      {/* Footer */}
      <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Drone guide may not work correctly (you use it at your own risk).
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </>
  );
}
